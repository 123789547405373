import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectComponent } from './project/project.component';
import { InventoryComponent } from './inventory/inventory.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthService } from './services/auth.service';
import { LoginComponent } from './login/login.component';
import { UsersComponent } from './users/users.component';
import { ContainersComponent } from './containers/containers.component';
import { ContainerComponent } from './container/container.component';
import { BinsComponent } from './bins/bins.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/projects',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthService]
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthService]
  },
  {
    path: 'project/:id',
    component: ProjectComponent,
    canActivate: [AuthService]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthService],
  },
  {
    path: 'inventory',
    component: InventoryComponent,
    canActivate: [AuthService]
  },
  {
    path: 'containers',
    component: ContainersComponent,
    canActivate: [AuthService]
  },
  {
    path: 'container/:id',
    component: ContainerComponent,
    canActivate: [AuthService]
  },
  {
    path: 'bins',
    component: BinsComponent,
    canActivate: [AuthService]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
