import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { ErrorsService } from './errors.service';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable({
	providedIn: 'root'
})
export class ApiService {

	baseUrl = environment.baseUrl;
	requests = 0;

	constructor(
		private http: HttpClient,
		private cookie: CookieService,
		private router: Router,
		private Auth: AuthService,
		private Errors: ErrorsService,
		private spinner: NgxSpinnerService
	) { }

	login(user) {
		return this.http.post(this.baseUrl + 'login', user).toPromise().then((res: any) => {
			if (res.error) {
				this.Errors.setErrors(res.error);
			} else if (res.userToken) {
				var now = new Date();
				now.setDate(now.getDate() + 30);
				this.cookie.set('DBSToken', res.userToken, now);
				this.router.navigate(['/']);
				this.Auth.user = res;
				this.Errors.clear();
			}
			return res;
		});
	}

	logout() {
		this.cookie.delete('DBSToken');
		this.router.navigate(['/login']);
	}

	get(path) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.cookie.get('DBSToken')
			})
		};
		this.requests++;
		this.spinner.show();
		return this.http.get(this.baseUrl + path, httpOptions).toPromise().then(res => {
			this.requests--;
			if(!this.requests){
				this.spinner.hide();
			}
			return res;
		});
	}

	save(path, data) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.cookie.get('DBSToken')
			})
		};
		this.spinner.show();
		return this.http.post(this.baseUrl + path, data, httpOptions).toPromise().then(res => {
			this.spinner.hide();
			return res;
		});
	}

	delete(path) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.cookie.get('DBSToken')
			})
		};
		return this.http.delete(this.baseUrl + path, httpOptions).toPromise().then(res => {
			return res;
		});
	}
}

