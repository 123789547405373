import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ConfirmService } from '../services/confirm.service';

@Component({
	selector: 'app-containers',
	templateUrl: './containers.component.html',
	styleUrls: ['./containers.component.scss']
})
export class ContainersComponent implements OnInit {

	_containers: any;
	search : any = '';

	constructor(
		private Api: ApiService,
		private Confirm: ConfirmService
	) { }

	ngOnInit() {
		this.Api.get('containers').then(conainers => {
			this._containers = conainers;
		});
	}

	deleteContainer(container) {
		let idx = this.containers.findIndex(p => p.containerToken === container.containerToken);

		this.Confirm.ask("Confirm!", "Are you sure you want to delete?").then((res) => {
			this.Api.delete('containers/' + container.containerToken).then(res => {
				this.containers.splice(idx, 1);
			});
		})
	}

	get containers(): any {
		return this._containers || [];
	}

}
