import { Component } from '@angular/core';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'app';
	
	constructor(
		private Api: ApiService,
		private Auth: AuthService,
		private router: Router,
	) {

		if(this.isLoggedIn){
			this.Api.get('/users/' + this.Auth.getUserToken()).then(user => {
				this.Auth.user = user;
			});
		}

	}

	get isLoggedIn(): any {
		return this.Auth.isLoggedIn();
	}

	logout(){
		this.Api.logout();
	}

	get user():any{
		return this.Auth.user;
	}
}
