import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter',
	pure: false
})
export class FilterPipe implements PipeTransform {

	transform(items: any[], field: string, value: any): any[] {
		if (!items || !field || !value) {
			return items;
		}else if(typeof value === "string"){
			return items.filter(item => item[field].toLowerCase().indexOf(value.toLowerCase()) > -1);
		}else if(typeof value === "object" && value.length){
			return items.filter(item => {
				return value.indexOf(item[field]) > -1
			});
		}else{
			return items;
		}
		
	}

}
