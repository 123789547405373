import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ErrorsService } from '../services/errors.service';
import { Location } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from '../services/confirm.service';

@Component({
	selector: 'app-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

	_container : any;
	id: any;
	carriers = [];
	modalRef: BsModalRef;
	projects : any;
	units : any;
	searchTerm = '';

	constructor(
		private Api: ApiService,
		private route: ActivatedRoute,
		private Errors : ErrorsService,
		private location: Location,
		private modalService: BsModalService,
		private toastr: ToastrService,
		private Confirm: ConfirmService
	) { }

	ngOnInit() {
		this.route.params.subscribe(params => {

			this.id = params['id']
			if (this.id !== 'new') {
				this.getContainer();
			}else{
				this._container = {};
			}

			this.Api.get('lists/carriers').then((carriers:any) => {
				this.carriers = carriers;
			});

		});

	}

	getContainer(){
		this.Api.get('containers/' + this.id + '/full').then(container => {
			this._container = container;
			let _containersItems = [];
			
			if(this._container.eta){
				this._container.eta = new Date(this._container.eta)
			}
			if(this._container.etd){
				this._container.etd = new Date(this._container.etd)
			}
			if(this._container.dateArrived){
				this._container.dateArrived = new Date(this._container.dateArrived)
			}

			for (let i in this.container.containerItems) {
				_containersItems = _containersItems.concat(this.container.containerItems[i]);
			}
			
			this._container.containerItems = _containersItems;

		});
	}

	saveContainer(){
		let path = 'containers';

		if(this.container.containerToken){
			path += ('/' + this.container.containerToken);
		}

		this.Errors.clear();

		this.Api.save(path, {
			containerNumber : this.container.containerNumber,
			carrier : this.container.carrier,
			eta : this.container.eta,
			etd : this.container.etd,
			dateArrived : this.container.dateArrived,
			notes : this.container.notes
		}).then((res:any) => {
			if(!this.container.containerToken && res.containerToken){
				this.id = res.containerToken;
				this._container = res;
				if(this._container.eta){
					this._container.eta = new Date(this._container.eta)
				}
				if(this._container.etd){
					this._container.etd = new Date(this._container.etd)
				}
				if(this._container.dateArrived){
					this._container.dateArrived = new Date(this._container.dateArrived)
				}
				this.location.replaceState("/container/" + res.containerToken);
				this.toastr.success('Container Created');
			}else if(res.error){
				this.Errors.setErrors(res.error);
			}else{
				this.toastr.success('Container Updated');
			}
		});
	}

	setUnitItemsExpected(item, direction, containerItem){
		let unallocatedBoxesCount = item.unit.unallocatedBoxesCount;
		item.originalItemsExpected = item.originalItemsExpected || item.itemsExpected;

		if(typeof containerItem.unallocatedBoxesCount === 'undefined'){
			containerItem.unallocatedBoxesCount = unallocatedBoxesCount;
		}

		if(direction === '+'){
				
			if(containerItem.unallocatedBoxesCount > 0){
			
				item.itemsExpected = item.itemsExpected +1;
				containerItem.unallocatedBoxesCount = containerItem.unallocatedBoxesCount - 1;

			}
			
			
		}else{			
			item.itemsExpected = Math.max(~~item.itemsExpected -1, 0);
			containerItem.unallocatedBoxesCount = containerItem.unallocatedBoxesCount + 1
		}

		this.updateItemCount(item);
	}

	setModalUnitItemsExpected(item, direction){
		if(direction === '+'){
			item.itemsExpected = Math.min(item.unallocatedBoxesCount, item.itemsExpected +1);
		}else{
			item.itemsExpected = Math.max(~~item.itemsExpected -1, 0);
		}
	}

	updateItemCount(item){
		if(item.containerItemToken){
			this.Api.save('containeritems/' + item.containerItemToken, {
				containerToken : item.containerToken,
				itemsExpected : item.itemsExpected
			}).then((res:any) => {
				if(res.error){
					this.Errors.setErrors(res.error);
				}else{
					this.Errors.clear();
					this.container.containerItemsAggregate = res.aggregate;
				}
			})
		}
	}

	deleteItem(containerItem, item){
		let idx = containerItem.items.findIndex(i => i.containerItemToken === item.containerItemToken);
		this.Confirm.ask("Confirm!", "Are you sure you want to delete?").then((res) => {
			this.Api.delete('containeritems/' + item.containerItemToken).then(res => {
				containerItem.items.splice(idx,1);
			});
		});		
	}

	openProjectUnits(template, project){
		
		if(this.modalRef){
			this.modalRef.hide();
		}
		
		this.searchTerm = '';
		this.units = [];
		this.Api.get('units/' + project.projectToken).then((units:any) => {			
			for (let u in units) {
				this.units = this.units.concat(units[u]);
			}
			this.units.forEach(u => {
				u.itemsExpected = u.unallocatedBoxesCount;
			});
		});
		this.modalRef = this.modalService.show(template, { class: 'modal-md', animated:false });
	}

	addProjectToContainer(template){
		if(!this.projects){
			this.Api.get('projects').then(projects => {
				this.projects = projects;
			})
		}
		this.searchTerm = '';
		this.modalRef = this.modalService.show(template, { class: 'modal-md', animated:false });
	}

	addUnits(){

		let units = this.getSelectedUnits.map(unit => {
			return {
				containerToken : this.container.containerToken,
				projectToken : unit.projectToken,
				unitToken : unit.unitToken,
				itemsExpected : unit.itemsExpected
			}
		})

		this.Api.save('containeritems', units).then(res => {
			
			this.getContainer();
			this.modalRef.hide();

		})
	}

	getProjectExpectedItemsCount(items){
		return items.reduce((total, item) => {
			return total + item.itemsExpected;
		}, 0);
	}

	get getSelectedUnits(): any{
		return this.units.filter(u => u.selected);
	}

	get container(): any {
		return this._container || {};
	}



}
