import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ConfirmService } from '../services/confirm.service';



@Component({
	selector: 'app-projects',
	templateUrl: './projects.component.html',
	styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

	_projects : any;
	search : any = '';

	constructor(
		private Api : ApiService,
		private Confirm : ConfirmService
	) { }

	ngOnInit() {
		this.Api.get('projects').then(projects => {
			this._projects = projects;
		});
	}

	deleteProject(project){
		let idx = this.projects.findIndex(p => p.projectToken === project.projectToken);
		
		this.Confirm.ask("Confirm!", "Are you sure you want to delete?").then((res)=>{
			this.Api.delete('projects/' + project.projectToken).then(res => {
				this.projects.splice(idx, 1);
			});
		})
	}

	get projects(): any{
		return this._projects || [];
	}

}
