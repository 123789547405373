import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-bins',
  templateUrl: './bins.component.html',
  styleUrls: ['./bins.component.scss']
})
export class BinsComponent implements OnInit {

  totalBins:any;
  bin: any = {};
  binLists: any = {};
  pallets: any = [];

  constructor(
    private Api: ApiService
  ) { }

  ngOnInit() {
    this.Api.get('bins/all').then(binLists => {
      this.binLists = binLists;
    });
    this.Api.get('reports/occupiedbinscount').then(totalBins => {
      this.totalBins = totalBins;
    });
  }

  getBinPallets() {
    if (this.bin.aisle && this.bin.section && this.bin.sectionDetail && this.bin.level) {
      const binToken = `bin_${this.bin.aisle}-${this.bin.section}${this.bin.sectionDetail}-${this.bin.level}`;
      this.Api.get('pallets/' + binToken).then(res => {
        this.pallets = res;
      });
    }
  }

  get canSearchForBin(): any {
    return this.bin.aisle && this.bin.section && this.bin.sectionDetail && this.bin.level;
  }

  get totalBoxes(): any {
    return this.pallets.reduce((total, pallet) => {
      return total + pallet.itemCount;
    }, 0);
  }

}
