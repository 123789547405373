import { Component, OnInit, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ErrorsService } from '../services/errors.service';
import { ConfirmService } from '../services/confirm.service';
import { ToastrService } from 'ngx-toastr';

import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

	_project: any;
	id: any;
	modalRef: BsModalRef;
	_unit = {
		boxesCount: 0
	};
	selectedFloors = [];
	statuses: any;

	constructor(
		private Api: ApiService,
		private route: ActivatedRoute,
		private modalService: BsModalService,
		private location: Location,
		private Errors: ErrorsService,
		private Confirm: ConfirmService,
		private toastr: ToastrService,
		private spinner: NgxSpinnerService
	) {
		pdfMake.vfs = pdfFonts.pdfMake.vfs;
	}

	ngOnInit() {

		this.route.params.subscribe(params => {

			this.id = params['id']
			if (this.id !== 'new') {
				this.Api.get('projects/' + this.id + '/full').then(project => {
					this._project = project;
					let _units = [];
					for (let u in this.project.units) {
						_units = _units.concat(this.project.units[u]);
					}
					this._project.units = _units;
					this._project.floors = this._project.floors.map(floor => {
						return {
							floor: floor
						}
					});
				});
			} else {
				this._project = {};
			}

		});

		this.Api.get('lists/projectstatus').then(res => {
			this.statuses = res;
		});
	}

	saveProject() {

		let path = 'projects';

		if (this.project.projectToken) {
			path += ('/' + this.project.projectToken);
		}

		this.Errors.clear();

		this.Api.save(path, this.project).then((res: any) => {
			if (!this.project.projectToken && res.projectToken) {
				this.id = res.projectToken;
				this._project = res;
				this.location.replaceState("/project/" + res.projectToken);
				this.toastr.success('Project Created');
			} else if (res.error) {
				this.Errors.setErrors(res.error);
			} else {
				this._project.statusValue = this.statuses.find(s => s.id === res.status).name;
				this.toastr.success('Project Updated');
			}
		});
	}

	openUnitModal(template: TemplateRef<any>, unit) {

		this.Errors.clear();

		if (unit) {
			this._unit = Object.assign({}, unit);
			if (this.unit.deliveryDateKitchen) {
				this.unit.deliveryDateKitchen = new Date(this.unit.deliveryDateKitchen)
			}
		} else {
			this._unit = {
				boxesCount: 0
			}
		}
		this.modalRef = this.modalService.show(template, { class: 'modal-md' });
	}

	setBoxCount(direction) {
		this.unit.boxesCount = direction === '+' ? this.unit.boxesCount + 1 : Math.max(this.totalContainerItemsItemsExpected, this.unit.boxesCount - 1);
		if (this.unit.boxesCount >= this.totalContainerItemsItemsExpected) {
			this.Errors.clear();
		}
	}

	saveUnit() {
		let path = 'units';

		if (this.unit.unitToken) {
			path += ('/' + this.unit.unitToken);
		}

		this.Api.save(path, {
			unitNumber: this.unit.unitNumber,
			floor: this.unit.floor,
			deliveryDateKitchen: this.unit.deliveryDateKitchen,
			boxesCount: this.unit.boxesCount,
			projectToken: this.project.projectToken
		}).then((res: any) => {
			if (res.unitToken) {
				if (!this.unit.unitToken) {
					if (this.project.units) {
						this.project.units.push(res);
					} else {
						this.project.units = [res];
					}
				} else {
					let unit = this.units.find(u => u.unitToken === this.unit.unitToken);
					if (unit) {
						Object.assign(unit, ...res)
					}
				}
				this.modalRef.hide();
			} else if (res.error) {
				this.Errors.setErrors(res.error);
			}

		});
	}

	deleteUnit(unit) {
		this.Confirm.ask("Confirm!", "Are you sure you want to delete?").then((res) => {
			this.Api.delete('units/' + unit.unitToken).then(res => {
				let unitIdx = this.units.findIndex(u => u.unitToken === unit.unitToken);
				this.units.splice(unitIdx, 1);
			});
		});
	}

	toggleUnitPallets(unit) {
		if (unit.showPallets) {
			unit.showPallets = false;
		} else {
			this.units.forEach(u => {
				u.showPallets = false;
			});
			unit.showPallets = true;
		}

	}

	selectedAllUnits(selected) {
		this.units.forEach(unit => {
			if(this.unitHasPalletsInBins(unit)){
				unit.selected = selected;
				if (unit.pallets) {
					unit.pallets.forEach(pallet => {
						pallet.selected = selected;
					});
				}
			}else{
				unit.selected = false;
			}
		});
	}

	selectAllPallets(unit, selected) {
		unit.pallets.forEach(pallet => {
			pallet.selected = pallet.binToken && selected;
		});
	}

	toggleFloor(floor) {
		let idx = this.selectedFloors.indexOf(floor.floor);
		if (idx > -1) {
			this.selectedFloors.splice(idx, 1);
		} else {
			this.selectedFloors.push(floor.floor)
		}
	}

	setContaineritemsExpected(direction, item) {
		if (direction === '+') {
			if (this.totalContainerItemsItemsExpected < this.unit.boxesCount) {
				item.itemsExpected = ~~item.itemsExpected + 1;
			}
		} else {
			item.itemsExpected = Math.max(~~item.itemsExpected - 1, 0);
		}
		this.updateContaineritemsExpected(item);
	}

	updateContaineritemsExpected(item) {

		if (item.containerToken && item.containerItemToken) {
			this.Api.save('containeritems/' + item.containerItemToken, {
				containerToken: item.containerToken,
				itemsExpected: item.itemsExpected
			}).then((res: any) => {
				if (res.error) {
					this.Errors.setErrors(res.error);
				} else {
					this.Errors.clear();
				}
			})
		}
	}

	downloadPickList() {
		
		this.spinner.show('picklist', {
			type: 'ball-clip-rotate',
			size: 'small',
			bdColor: 'rgba(51,51,51,0.5)',
			color: 'white',
			fullScreen: false
		});
		

		const selectedPallets = this.units.filter(unit => {
			return unit.pallets && unit.pallets.find(p => p.selected);
		}).map(unit => unit.pallets).flat(1);
		const selectedAccessories = this.accessories.filter(pallet => pallet.selected);
		const palletsTable = [];

		selectedPallets.forEach(pallet => {
			if (pallet.selected) {
				palletsTable.push([pallet.palletNumber, 'Boxes: ' + pallet.itemCount, 'Unit: ' + (pallet.unitToken ? pallet.unit.unitNumber : ''), 'Bin: ' + (pallet.binToken ? pallet.bin.binName : '')]);
			}
		});

		selectedAccessories.forEach(pallet => {
			palletsTable.push([pallet.palletNumber, 'Boxes: ' + pallet.itemCount, 'Unit: ' + (pallet.unitToken ? pallet.unit.unitNumber : ''), 'Bin: ' + (pallet.binToken ? pallet.bin.binName : '')]);
		});

		const docDefinition = {
			content: [
				{
					table: {
						widths: '*',
						body: [
							[
								{ text: 'PICK LIST', 'fontSize': 18, 'bold': true, 'margin': [0, 0, 0, 10] },
								{ text: `Date Created ${new Date().toLocaleDateString()}`, alignment: 'right' },
							]
						]
					},
					layout: 'lightHorizontalLines'
				},
				{
					text: [
						{ text: 'Project ', fontSize: 12, bold: true },
						{ text: this.project.name, fontSize: 12 }
					],
					margin: [0, 0, 0, 5]
				},
				{
					text: [
						{ text: 'Address ', fontSize: 12, bold: true },
						{ text: this.project.deliveryAddress, fontSize: 12 }
					]
				},
				{ text: 'Pallets', margin: [0, 16, 0, 5], bold: true },
				{
					margin: [0, 0, 0, 15],
					table: {
						widths: '*',
						body: palletsTable
					},
					layout: {
						fillColor: function (rowIndex, node, columnIndex) {
							return (rowIndex % 2 === 0) ? '#CCCCCC' : null;
						},
						'vLineWidth': function () {
							return 0;
						},
						'hLineWidth': function () {
							return 0;
						},
						'paddingTop': function () {
							return 10;
						},
						'paddingBottom': function () {
							return 10;
						},
						'paddingLeft': function () {
							return 10;
						},
						'paddingRight': function () {
							return 10;
						}
					}
				}
			]
		};

		setTimeout(() => {
			pdfMake.createPdf(docDefinition).download('picklist.pdf');
		}, 100)
		setTimeout(() => {
			this.spinner.hide('picklist');
		}, 2000)
	}

	unitHasPalletsInBins(unit){
		return unit.pallets && unit.pallets.find(p => p.binToken);
	}

	get showPicklist(): any {
		let selectedUnit = this.units.find(unit => {
			return unit.pallets && unit.pallets.find(p => p.selected);
		});
		let selectedPallet = this.accessories.find(p => p.selected);

		return selectedUnit || selectedPallet;
	}

	get project(): any {
		return this._project || {};
	}

	get units(): any {
		return this.project.units;
	}

	get unit(): any {
		return this._unit || {};
	}

	get accessories(): any {
		return this.project.noUnitPallets && this.project.noUnitPallets.no_unit ? this.project.noUnitPallets.no_unit : [];
	}

	get totalContainerItemsItemsExpected(): any {
		return this.unit.containerItems && this.unit.containerItems.reduce((total, item) => {
			return total + item.itemsExpected;
		}, 0)
	}

}
