import { Component, OnInit, TemplateRef } from '@angular/core';
import { ApiService } from '../services/api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ErrorsService } from '../services/errors.service';
import { ConfirmService } from '../services/confirm.service';

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

	modalRef: BsModalRef;
	_users: any;
	_user: any;
	roles = [];
	search : any = '';

	constructor(
		private Api: ApiService,
		private modalService: BsModalService,
		private Errors : ErrorsService,
		private Confirm : ConfirmService
	) { }

	ngOnInit() {
		this.Api.get('users').then(users => {
			this._users = users;
		});
		this.Api.get('lists/roles').then((roles:any) => {
			this.roles = roles;
		});
	}

	openUserModal(template?: TemplateRef<any>, user?: any) {
		this.Errors.clear();
		this._user = user || {};
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
	}

	saveUser() {
		let path = 'users';

		if (this.user.userToken) {
			path += ('/' + this.user.userToken);
		}

		this.Api.save(path, this.user).then((res: any) => {
			if(res.userToken){
				if(!this.user.userToken){
					if(this._users){
						this._users.push(res);
					}else{
						this._users = [res];
					}					
				}else{
					let user = this.users.find(u => u.userToken === this.user.userToken);
					if(user){
						Object.assign(user, ...res)
					}
				}			
				this.modalRef.hide();
			}else if(res.error){
				this.Errors.setErrors(res.error);
			}
		});
		
	}

	deactivateUser() {

		if (this.user.userToken) {
			this.user.active = this.user.active === 1 ? 0 : 1;
			this.Api.save('users/' + this.user.userToken, {
				active : this.user.active
			}).then((res: any) => {
				if(res.userToken){
					this.modalRef.hide();
				}else if(res.error){
					this.Errors.setErrors(res.error);
				}
			});
		}else{
			this.modalRef.hide();
		}
		
	}

	deleteUser(user){
		let idx = this.users.findIndex(p => p.userToken === user.userToken);
		
		this.Confirm.ask("Confirm!", "Are you sure you want to delete?").then((res)=>{
			this.Api.delete('users/' + user.userToken).then(res => {
				this.users.splice(idx, 1);
			});
		})

	}

	get users(): any {
		return this._users || [];
	}

	get user(): any {
		return this._user || {};
	}

}
